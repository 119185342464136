import { useEffect, useState } from "react";

import { data } from "./data";
import { structureGoogleDataUrl } from "../../constants";

const StructureData = ({ url, language }) => {
  const [isCurrentPage, setCurrentPage] = useState(false);
  const [correctUrl, setCorrectUrl] = useState(null);

  const currentPage = () => {
    const pathName = url.includes(`${language}/`) ? url.slice(3) : url;
    setCorrectUrl(pathName);
    setCurrentPage(structureGoogleDataUrl.includes(pathName));
  };

  useEffect(() => {
    document.addEventListener("load", currentPage());
    return () => document.removeEventListener("load", () => currentPage());
  }, [isCurrentPage]);

  return (
    isCurrentPage && (
      <div
        id="structureGoogleData"
        dangerouslySetInnerHTML={{
          __html: data[correctUrl],
        }}
      />
    )
  );
};

export default StructureData;
