import "./style.scss";
import { createElement } from "react";
import PropTypes from "prop-types";

import Mark from "../../Marker";
import { createClassName } from "../../../helpers";

const cn = createClassName("heading");

const HeadingWithAccentWords = (props) => {
  const accentTitle = (
    <>
      {/* {props.beforetitle && String.fromCharCode(32)} */}
      <span
        className={cn("accent-words", {
          color: props.accentColor,
          "space-before": !!props.beforetitle,
          "space-after": !props.isComma,
        })}
      >
        {props.accentTitle}
      </span>
      {/* {!props.isComma && String.fromCharCode(32)} */}
    </>
  );

  function subtitle(val) {
    return (
      <span
        className={cn("sub", {
          color: props.subColor,
          weight: props.subTitleWeight,
        })}
      >
        {props.isComma ? `, ${val}` : val}
      </span>
    );
  }
  const beforeAccentTitle = props.beforetitle ? subtitle(props.beforetitle) : null;
  const afterAccentTitle = subtitle(props.subTitle);

  return createElement(
    `h${props.level}`,
    {
      className: `${cn({
        "with-accent-word": true,
        level: props.fontLevel || props.level,
      })} ${props.className}`,
    },
    props.new && <Mark />,
    beforeAccentTitle,
    accentTitle,
    afterAccentTitle,
  );
};

HeadingWithAccentWords.defaultProps = {
  accentColor: "primary",
  subColor: "default",
  level: 1,
  subTitleWeight: "normal",
  className: "",
  fontLevel: 0,
  new: false,
  beforetitle: "",
  isComma: false,
};

HeadingWithAccentWords.propTypes = {
  beforetitle: PropTypes.string,
  accentTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  subTitle: PropTypes.string,
  subTitleWeight: PropTypes.string,
  accentColor: PropTypes.string,
  subColor: PropTypes.string,
  level: PropTypes.number,
  fontLevel: PropTypes.number,
  className: PropTypes.string,
  new: PropTypes.bool,
  isComma: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default HeadingWithAccentWords;
