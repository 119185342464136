import "./style.scss";

import PropTypes from "prop-types";
import { useContext } from "react";
import { useTranslation } from "react-i18next/dist/es/useTranslation";
import {
  createClassName,
  setLocaleContent,
} from "../../helpers";

import AccentContainer from "../Container/_accent";
import Container from "../Container";
import Heading from "../Heading";
import { LocaleContext } from "../../layouts/LocaleContext";
import { useImageRendering } from "../Image";

const cn = createClassName("accent-banner");

const SubTitle = (props) => (
  <Heading
    level={3}
    fontLevel={4}
    color="white"
    className={props.subtitleClassName}
  >
    {props.subTitle}
  </Heading>
  );

const AccentBanner = (props) => {
  const { t } = useTranslation("strings");
  const Image = useImageRendering(props);
  const { isMobile } = useContext(LocaleContext);
  const bannerImageSrc = `xltools-add-in-for-excel-ribbon${setLocaleContent("", "-")}.png`;

  return (
    <AccentContainer className={cn("main-wrap")}>
      <Container className={`${cn("container")} ${props.className}`}>
        <div className={`${cn("title")} ${props.titleClassName}`}>
          <Heading
            level={2}
            weight="bold"
            color="white"
            className={props.headingClassName}
          >
            {props.title}
          </Heading>
          {props.subTitle && <SubTitle {...props} />}
        </div>
        <div className={`${cn("button")} ${props.buttonClassName}`}>
          {props.children}
          <p className={cn("button", { description: true })}>
            {props.buttonDescription}
          </p>
        </div>
        <div className={`${cn("image", { wrap: true })} ${props.imgClassName}`}>
          <div className={cn("image", { picture: true })}>
            {!isMobile && (
              <Image
                src={bannerImageSrc}
                alt={t("alt.excel-ribbon")}
                aria-hidden="true"
                className={cn("image", { picture: "shadow" })}
              />
            )}
          </div>
        </div>
      </Container>
    </AccentContainer>
  );
};

AccentBanner.defaultProps = {
  title: "",
  children: "",
  buttonDescription: "",
  className: "",
  imgClassName: "",
};

AccentBanner.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  children: PropTypes.node.isRequired,
  subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  buttonDescription: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  className: PropTypes.string,
  titleClassName: PropTypes.string,
  imgClassName: PropTypes.string,
  headingClassName: PropTypes.string,
  subtitleClassName: PropTypes.string,
};

export default AccentBanner;
