import { useCallback } from "react";
// import PropTypes from "prop-types";

import { createClassName } from "../../helpers";
import "./style.scss";
import LazyLoad from "react-lazy-load";

const cn = createClassName("video");

export const useVideoRendering = (props) => props.videoRenderer || null;

export const videoManager = (pageVideos) => useCallback(
    (props) => {
      const sources = props.sources.map(({ src }) => `video/${src}`);
      const videos = pageVideos.nodes.filter(({ relativePath }) => sources.includes(relativePath));

      const video = (
        <video
          className={`${cn()} ${props.className || ""}`}
          loop={props.isLoop}
          muted={props.isMuted || props.autoPlay}
          // ref={props.forwardedRef}
          autoPlay={props.autoPlay}
        >
          {videos.map(({ publicURL, extension }, i) => (
            <source
              src={publicURL}
              type={`video/${extension}`}
              key={i}
            />
          ))}
        </video>
);

      return props.lazy
        ? (
          <LazyLoad
            once
            offsetVertical={100}
            throttle={0}
          >
            {video}
          </LazyLoad>
)
        : video;
  }, [pageVideos],
);

// const Video = (props) => (
//   <video
//     className={`${cn()} ${props.className}`}
//     loop={props.isLoop}
//     muted={props.isMuted}
//     ref={props.forwardedRef}
//     autoPlay={props.autoPlay}
//   >
//     {props.sources.map((item) => (
//       <source
//         src={require(`../../assets/video/${item.src}`)}
//         type={item.type}
//         key={item.src}
//       />
//     ))}
//   </video>
// );

// Video.defaultProps = {
//   className: "",
//   isLoop: true,
//   isMuted: true,
//   autoPlay: false,
//   forwardedRef: null,
// };

// Video.propTypes = {
//   sources: PropTypes.arrayOf(
//     PropTypes.shape({
//       src: PropTypes.string,
//       type: PropTypes.string,
//     }),
//   ).isRequired,
//   isLoop: PropTypes.bool,
//   isMuted: PropTypes.bool,
//   autoPlay: PropTypes.bool,
//   forwardedRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
//   className: PropTypes.string,
// };

// export default React.forwardRef((props, ref) => (
//   <Video {...props} forwardedRef={ref} />
// ));
